<template>
      <v-col cols="12">
    <div class="custom-heading">Volné pokoje</div>
        <v-btn
          v-if="displayEquipment === false && responsiveFilter <= 750"
          @click="displayEquipment = true"
          color="hotelsBlue"
          class="text-lowercase float-end mb-3"
          dark
        >
          Zobrazit vybavení
        </v-btn>
        <v-btn
          v-if="responsiveFilter <= 750 && displayEquipment === true"
          @click="displayEquipment = false"
          color="hotelsOrange"
          class="text-lowercase float-end mb-3"
          dark
        >
          Skrýt vybavení
        </v-btn>
    <table id="room-table">
      <thead>
      <th>Název</th>
      <th>Info</th>
      <th v-if="displayEquipment || responsiveFilter > 750">Vybavení</th>
      <th>Cena</th>
      <th>Rezervace</th>
      </thead>
      <tbody>
      <tr v-for="(unit, index) in availableRooms" :key="index">
        <td class="font-weight-bold">{{ unit.name }}</td>
        <td style="color: #515151">
          <div class="text-no-wrap">
            <v-icon small color="hotelsBlue">mdi-account</v-icon>
            {{ unit.capacity }} osob{{
              unit.capacity === 1 ? 'a'
                : (unit.capacity >= 5) ? ''
                  : 'y'
            }}
          </div>
          <div class="text-no-wrap">
            <v-icon small color="hotelsBlue">mdi-bed</v-icon>
            {{ unit.bedsQuantity }} lůž{{
              unit.bedsQuantity === 1 ? 'ko'
                : (unit.bedsQuantity >= 2) ? 'ka'
                  : 'ek'
            }}
          </div>
        </td>
        <td  v-if="displayEquipment || responsiveFilter > 750">
          <div class="d-flex flex-wrap">
            <div class="equipment-check align-center d-flex mr-2 ml-2"
                  v-for="(eq, i) in JSON.parse(unit.equipment)" :key="i"
            >
              <v-icon color="green" class="mr-1">
              mdi-check</v-icon>
              {{ eq.name }}
            </div>
          </div>
        </td>
        <td class="font-weight-bold text-no-wrap" id="hotelsOrange">{{ unit.price }} Kč</td>
        <td>
          <v-btn
            v-if="isLogged && user.id !== property.owner.id && user.roleId === 4"
            color="hotelsBlue"
            dark
            class="text-lowercase rounded"
            @click="redirectToDemand(unit)"
          >Poptat</v-btn>
          <span v-else>Pro poptání se přihlašte</span>
        </td>
      </tr>
      </tbody>
    </table>
      </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    availableRooms: {
      required: true,
      type: Array
    }
  },
  name: 'AvailableRooms',
  data () {
    return {
      displayEquipment: false
    }
  },
  methods: {
    redirectToDemand (unit) {
      const query = {
        property: this.$route.params.id,
        room: unit.id,
        dateFrom: this.$route.query.dateFrom,
        dateTo: this.$route.query.dateTo,
        adults: this.$route.query.adults,
        children: this.$route.query.children
      }
      this.$router.push({ name: 'create-demand', query })
    }
  },
  computed: {
    responsiveFilter () {
      return screen.availWidth
    },
    ...mapGetters({
      isLogged: 'userAuth/isLogged',
      isUser: 'userAuth/isUser',
      user: 'userAuth/user',
      property: 'detailViews/property'
    })
  }
}
</script>

<style scoped>
#room-table{
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif;
}
#room-table td, #room-table th{
  border: 1px solid #ddd;
  padding: 8px;
}
#room-table tr:nth-child(even){background-color: #f2f2f2;}

#room-table tr:hover {background-color: #ddd;}
.equipment-check{
  font-size: 13px;
}
#hotelsOrange{
  color: var(--v-hotelsOrange-base);
}
</style>
