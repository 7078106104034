<template>
  <v-container>
<!--    <v-row class="mt-1 justify-center">-->
<!--      <SimpleSearchForm @search-init="search"></SimpleSearchForm>-->
<!--    </v-row>-->
    <template v-if="!loading">
      <DetailMiniCard />
      <div>
        <v-row>
          <v-col cols="12">
            <div class="custom-heading">Dostupnost</div>
            <div id="availability-container" class="d-flex justify-space-between">
              <div id="checkin" class="d-block">
                <span class="avail-title">Datum příjezdu</span><br>
                <span class="avail-date">{{searchParameters.dateFrom | localizedDate}}</span><br>
                <span class="avail-subtitle">{{ property.checkInFrom }}-{{ property.checkInTo }}</span>
              </div>
              <div id="checkout">
                <span class="avail-title">Datum odjezdu</span><br>
                <span class="avail-date">{{searchParameters.dateTo | localizedDate}}</span><br>
                <span class="avail-subtitle">{{ property.checkOutFrom }}-{{ property.checkOutTo }}</span>
              </div>
              <div id="people">
                <span class="avail-title">Hosté</span><br>
                <span class="avail-date" v-html="guestsText"></span>
              </div>
              <div id="new-search" class="align-self-center">
                <v-btn
                  color="hotelsBlue"
                  class="text-lowercase"
                  dark
                  @click="resetSearch"
                >Nové vyhledávání</v-btn>
              </div>
            </div>
          </v-col>
          <AvailableRooms
            :available-rooms="availableRooms"
          ></AvailableRooms>
        </v-row>
      </div>
    </template>
    <v-skeleton-loader type="article" v-else />
  </v-container>
</template>

<script>
// TODO: favourite
// import SimpleSearchForm from '../../components/search/SimpleSearchForm'
import AvailableRooms from '../../components/property/AvailableRooms'
import DetailMiniCard from '../../components/property/detail/DetailMiniCard'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'PropertyDetail',
  components: { DetailMiniCard, AvailableRooms },
  data () {
    return {
      loading: false,
      availableRooms: []
    }
  },
  computed: {
    guestsText: function () {
      let text = ''
      if (this.searchParameters.adults > 0) {
        text += `Počet dospělých: ${this.searchParameters.adults}<br>`
      }

      if (this.searchParameters.children > 0) {
        text += `Počet dětí: ${this.searchParameters.children}`
      }
      return text
    },
    ...mapGetters({
      property: 'detailViews/property',
      searchParameters: 'search/searchParameters'
    })
  },
  methods: {
    /**
     * Checks route query parameters:
     *    adults, children - greater than 0
     *    dateFrom, dateTo - date in format yyyy-mm-dd
     *
     * @return boolean
     */
    checkValidRouteParameters () {
      // important
      // /property-detail/1?dateFrom=2022-01-20&dateTo=2022-01-21&adults=1&children=1
      const query = this.$route.query
      if (query.adults && +query.adults < 0) {
        return false
      }
      if (query.children === undefined || (query.children && +query.children < 0)) {
        return false
      }
      if (query.adults === undefined || (+query.adults + +query.children <= 0)) {
        return false
      }
      const dateFrom = new Date(query.dateFrom)
      const dateTo = new Date(query.dateTo)
      if (isNaN(dateFrom.getTime()) || isNaN(dateTo.getTime())) {
        return false
      }
      if (dateFrom.getTime() > dateTo.getTime()) {
        return false
      }
      // dateFrom v minulosti
      if (dateFrom.getTime() < (new Date()).getTime()) {
        return false
      }

      return true
    },
    /**
     * Redirects to homepage
     * @return void
     */
    resetSearch () {
      window.scrollTo(0, 0)
      this.$router.push({ name: 'homepage' })
    },

    search (parameters) {
      this.$router.push({
        name: 'search-result',
        query: parameters
      })
    },
    ...mapMutations({
      setSearchParameters: 'search/setSearchParameters',
      setProperty: 'detailViews/setProperty'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  created () {
    if (!this.checkValidRouteParameters()) {
      this.$router.push({ name: 'homepage' })
    }
    this.setSearchParameters(this.$route.query)

    this.loading = true
    this.$http.post(`/search/properties/${this.$route.params.id}`, this.keysToSnakeCase(this.$route.query))
      .then(res => {
        this.loading = false
        const property = res.data.property
        property.rooms = res.data.rooms
        this.availableRooms = res.data.rooms
        // TODO: zjistit jestli to neco dela
        /* if (!this.searchParameters.locality.displayName) {
          this.setSearchParameters({
            ...this.searchParameters,
            locality: {
              displayName: res.data.property.localityDisplayName
            }
          })
          // this.$store.state.search.searchParameters.locality.displayName = res.data.property.localityDisplayName
        } */

        this.setProperty(property)
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.setAlerts([{ message: 'Objekt nenalezen', type: 'error' }])
        } /* else {
          this.$router.push({ name: 'homepage' })
        } */
      })
  }
}
</script>

<style scoped>
#availability-container{
  border: 1px solid grey;
  border-radius: 5px;
  padding: 12px;
}
.avail-title{
  font-weight: 450;
  font-size: 14px;
}
.avail-date{
  font-weight: bold;
  font-size: 18px;
  color: var(--v-hotelsBlue-base);
}
.avail-subtitle{
  font-size: 12px;
  color: #888888
}
</style>
